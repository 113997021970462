import "./styles/reset.css";
import "./styles/main.css";
import "./styles/greetings.css";

import ThreeCanvas from "./components/ThreeCanvas";
import { ContextProvider } from "./context/utils/ContextProvider";
// import Greetings from "./components/Greetings";

function App() {
  return (
    <ContextProvider>
      <div className="App">
        <ThreeCanvas />
      </div>
    </ContextProvider>
  );
}

export default App;
