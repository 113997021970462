import { useFrame, useLoader } from "@react-three/fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import texture from "../assets/2k_earth_daymap.jpg";
import React from "react";
import { editable as e } from "@theatre/r3f";
import { Sphere } from "@react-three/drei";

function GlobeSpinning() {
  const colorMap = useLoader(TextureLoader, texture);
  const myMesh = React.useRef();

  useFrame(({ clock }) => {
    const a = clock.getElapsedTime();
    myMesh.current.rotation.y = a / 10;
  });

  return (
    <e.mesh theatreKey="Globe">
      <Sphere
        ref={myMesh}
        visible
        args={[1.3, 100, 100]}
        position={[0, -1.4, -1]}
      >
        <meshStandardMaterial map={colorMap} />
      </Sphere>
    </e.mesh>
  );
}
export default GlobeSpinning;
