import * as THREE from "three";

import { BufferAttribute, BufferGeometry, PointsMaterial } from "three";

function Starfield(props) {
  // Create a buffer geometry with random positions for each star
  const numStars = props.numStars;
  const geometry = new BufferGeometry();
  const positions = new Float32Array(numStars * 3);
  const sizes = [0.1, 0.3, 0.6];

  for (let i = 0; i < numStars; i++) {
    const x = (Math.random() - 0.5) * props.width;
    const y = (Math.random() - 0.5) * props.height;
    const z = (Math.random() - 0.5) * props.depth;
    positions[i * 3] = x;
    positions[i * 3 + 1] = y;
    positions[i * 3 + 2] = z;
  }

  geometry.setAttribute("position", new BufferAttribute(positions, 3));
  geometry.setAttribute(
    "size",
    new THREE.Float32BufferAttribute(sizes, 1).setUsage(THREE.DynamicDrawUsage)
  );

  // Create a points material with a white color and a small size
  const material = new PointsMaterial({
    color: 0xffffff,
    size: props.size,
  });

  // Create a points object and render it
  return <points geometry={geometry} material={material} />;
}

export default Starfield;
