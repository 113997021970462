import React, { useState } from "react";
import { useFrame } from "@react-three/fiber";

export const GeneralContext = React.createContext();

export const GeneralProvider = ({ children }) => {
  // position
  const [cameraPosition, setCameraPosition] = useState([0, 0, 1]);
  const [lightPosition, setLightPosition] = useState([0, 0, -20]);
  const [titlePosition, setTitlePosition] = useState([0, 0.1, 0]);
  const [subtitlePosition, setSubtitlePosition] = useState([0, 0.03, 0]);
  const [startAnimation, setStartAnimation] = useState(false);

  // refs
  const [lightRef, setLightRef] = useState(false);
  const [titleRef, setTitleRef] = useState(false);
  const [subtitleRef, setSubtitleRef] = useState(false);

  const [globeAnimations, setGlobeAnimations] = useState(true);

  // props
  const fontProps = {
    font: "/Poppins-Medium.ttf",
    letterSpacing: -0.05,
    lineHeight: 1,
    "material-toneMapped": false,
  };

  // function CameraRig({ position: [x, y, z] }) {
  //   useFrame((state) => {
  //     state.camera.position.lerp({ x, y, z }, 0.001);
  //     state.camera.lookAt(0, 0, 0);
  //     state.camera.updateProjectionMatrix();
  //   });
  // }
  //
  // function LightRig({ position: [x, y, z] }) {
  //   useFrame((state) => {
  //     if (lightRef.current) {
  //       lightRef.current.position.lerp({ x, y, z }, 0.001);
  //     }
  //   });
  // }
  //
  // function TitleRig({ position: [x, y, z] }) {
  //   useFrame((state) => {
  //     if (titleRef.current) {
  //       titleRef.current.position.lerp({ x, y, z }, 0.0001);
  //     }
  //   });
  // }
  //
  // function SubTitleRig({ position: [x, y, z] }) {
  //   useFrame((state) => {
  //     if (subtitleRef.current) {
  //       subtitleRef.current.position.lerp({ x, y, z }, 0.0001);
  //     }
  //   });
  // }

  return (
    <GeneralContext.Provider
      value={{
        titlePosition,
        subtitlePosition,
        setTitlePosition,
        setSubtitlePosition,
        cameraPosition,
        setCameraPosition,
        lightPosition,
        setLightPosition,
        lightRef,
        setLightRef,
        setTitleRef,
        setSubtitleRef,
        fontProps,
        // CameraRig,
        // LightRig,
        // TitleRig,
        // SubTitleRig,
        startAnimation,
        setStartAnimation,
        globeAnimations,
        setGlobeAnimations,
      }}
    >
      {children}
    </GeneralContext.Provider>
  );
};
