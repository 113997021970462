import React, { useContext, useEffect, useRef } from "react";
import { GeneralContext } from "../context/GeneralContext";
import { Text } from "@react-three/drei";

function MainTitle({ position, text = "Louis Perner" }) {
  const { fontProps, setTitleRef } = useContext(GeneralContext);

  const titleRef = useRef();

  useEffect(() => {
    setTitleRef(titleRef);
  }, []);

  return (
    <mesh ref={titleRef}>
      <Text
        {...fontProps}
        fontSize={0.3}
        scale={[0.25, 0.25, 0.25]}
        position={position}
        color={"white"}
      >
        {text}
      </Text>
    </mesh>
  );
}

export default MainTitle;
