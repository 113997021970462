import { useContext, useEffect, useState } from "react";
import React, { Suspense } from "react";
import { Canvas, extend, useFrame } from "@react-three/fiber";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";

import { GeneralContext } from "../context/GeneralContext";

import { getProject, val } from "@theatre/core";
import { editable as e, SheetProvider, useCurrentSheet } from "@theatre/r3f";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";

import demoProjectState from "../theater/sun_light_globe.theatre-project-state.json";
import { UnrealBloomPass } from "three-stdlib";

import Main from "./Main";
import MainScene from "./MainScene";
import Greetings from "./Greetings";
import NeuralNetwork from "../threejs/NeuralNetwork";

import studio from "@theatre/studio";
import extension from "@theatre/r3f/dist/extension";

const demoSheet = getProject("Demo Project", { state: demoProjectState }).sheet(
  "Demo Sheet"
);

// studio.initialize();
// studio.extend(extension);

const ThreeCanvas = () => {
  const [showContinue, setShowContinue] = useState(false);
  const [started, setStarted] = useState(false);
  const [show, setShow] = useState(true);

  extend({ EffectComposer, RenderPass, UnrealBloomPass });

  const { titlePosition, subtitlePosition, globeAnimations } =
    useContext(GeneralContext);

  useEffect(() => {
    // if (globeAnimations) {
    demoSheet.project.ready.then(() =>
      demoSheet.sequence
        .play({ iterationCount: 1, range: [1, 23.2] })
        .then(() => {
          // if (started) {
            setShow(false);
          // }

          setShowContinue(false);
          // setStarted(true);
        })
    );
    // }
  }, [started]);

  return (
    <>
      <Main demoSheet={demoSheet} />
      <div className={"canvas-container"}>
        {show ? (
          <Canvas
            camera={{ position: [0, 0, 1], fov: 30 }}
            style={{
              backgroundColor: "#fff",
              width: "100vw",
              height: "100vh",
            }}
            gl={{ preserveDrawingBuffer: true }}
          >
            {/*<ScrollControls pages={5}>*/}
            <SheetProvider sheet={demoSheet}>
              <Suspense fallback={null}>
                <MainScene
                  titlePosition={titlePosition}
                  subtitlePosition={subtitlePosition}
                  demoSheet={demoSheet}
                  show={show}
                />
              </Suspense>
            </SheetProvider>
            {/*</ScrollControls>*/}
          </Canvas>
        ) : (
            <iframe src='https://my.spline.design/untitled-10db5b0331f99efc59f74367f4a569a5/' frameBorder='0' width='100%' height='100%'></iframe>
        )}
      </div>
      {/*{showContinue && <h1 className={"continue"}>Continue...</h1>}*/}
      {/*{showContinue && <Greetings />}*/}
    </>
  );
};

export default ThreeCanvas;
