import React, { useContext, useEffect, useRef } from "react";
import { GeneralContext } from "../context/GeneralContext";
import { editable as e } from "@theatre/r3f";
import Star from "./Star";

function Sun(props) {
  const { setLightRef } = useContext(GeneralContext);

  const lightRef = useRef();

  useEffect(() => {
    setLightRef(lightRef);
  }, []);

  return (
    <group {...props}>
      <e.mesh theatreKey="Sun" color={"white"}>
        {/*<sphereBufferGeometry args={[2, 32, 32]} />*/}
        <Star {...props} scale={[0.1, 0.1, 0.1]} />
        <e.pointLight
          theatreKey="Light"
          ref={lightRef}
          color={"white"}
          intensity={2}
          position={[0, 0, -20]}
        />
      </e.mesh>
    </group>
  );
}

export default Sun;
