import { useContext, useState } from "react";
import { GeneralContext } from "../context/GeneralContext";
import { useCurrentSheet } from "@theatre/r3f";
import { useFrame } from "@react-three/fiber";

const Main = ({ demoSheet }) => {
  const {
    setCameraPosition,
    setLightPosition,
    setTitlePosition,
    setSubtitlePosition,
    setStartAnimation,
    setGlobeAnimations,
  } = useContext(GeneralContext);

  return (
    <div className={"main"}>
      <div className={"header"}>
        <div className={"title"}>Louis Perner</div>
        <div className={"subheader"}>
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    // setCameraPosition([0, 0.2, 1]);*/}
          {/*    // setLightPosition([0, 20, -20]);*/}
          {/*    // setTitlePosition([0, 0.18, 0]);*/}
          {/*    // setSubtitlePosition([0, -0.2, 0]);*/}
          {/*    // setStartAnimation(true);*/}
          {/*    setGlobeAnimations(true);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Start*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    demoSheet.sequence.play({*/}
          {/*      // direction: "reverse",*/}
          {/*      range: [5.9, 6],*/}
          {/*    });*/}
          {/*  }}*/}
          {/*>*/}
          {/*  ABOUT*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    demoSheet.sequence.play({*/}
          {/*      direction: "reverse",*/}
          {/*      range: [5.9, 6],*/}
          {/*    });*/}
          {/*  }}*/}
          {/*>*/}
          {/*  XP*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    demoSheet.sequence.play({ direction: "reverse", range: [9, 10] });*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Work*/}
          {/*</div>*/}
          {/*/!*<div onClick={() => {}}>EDUCATION</div>*!/*/}
          {/*<div onClick={() => {}}>SKILLS</div>*/}
          {/*<div>Contact</div>*/}
        </div>
      </div>
      <div className={"content"}>
        <div className={"home"}>
          {/*<h1>Louis Perner</h1>*/}
          {/*<h2>Creative Technologist</h2>*/}
          {/*<div className={"subheader"}>*/}
          {/*  <div*/}
          {/*    onClick={() => {*/}
          {/*      setCameraPosition([0, 0, 5]);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Work*/}
          {/*  </div>*/}
          {/*  <div>XP</div>*/}
          {/*  <div>Art</div>*/}
          {/*  <div>Prototypes</div>*/}
          {/*  <div>Contact</div>*/}
          {/*</div>*/}
        </div>
        <div className={"message"}>test</div>
      </div>
      <div className={"footer"}></div>
    </div>
  );
};

export default Main;
