import { extend } from "@react-three/fiber";
import { Effects } from "@react-three/drei";
import { UnrealBloomPass } from "three-stdlib";
import { useControls } from "leva";
import { editable as e } from "@theatre/r3f";

extend({ UnrealBloomPass });

export default function Star(props) {
  return (
    <mesh {...props} position={[0, -1.2, -200]}>
      <Effects disableGamma>
        <unrealBloomPass threshold={1} strength={1} radius={0.1} />
      </Effects>
      <Shape color={[255, 255, 255]}>
        <circleGeometry args={[0.8, 64]} />
      </Shape>
    </mesh>
  );
}

function Shape({ children, color, ...props }) {
  return (
    <mesh {...props}>
      {children}
      <meshBasicMaterial color={color} toneMapped={false} />
    </mesh>
  );
}
