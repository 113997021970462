import React, { useContext, useEffect, useRef } from "react";
import { GeneralContext } from "../context/GeneralContext";
import { Text } from "@react-three/drei";

function MainSubTitle({ position, text = "Creative Technologist" }) {
  const { fontProps, setSubtitleRef } = useContext(GeneralContext);

  const subTitleRef = useRef();

  useEffect(() => {
    setSubtitleRef(subTitleRef);
  }, []);

  return (
    <mesh ref={subTitleRef}>
      <Text
        {...fontProps}
        fontSize={0.3}
        scale={[0.1, 0.1, 0.1]}
        position={position}
        color={"white"}
      >
        {text}
      </Text>
    </mesh>
  );
}

export default MainSubTitle;
