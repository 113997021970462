import { editable as e } from "@theatre/r3f";
import MainTitle from "../threejs/MainTitle";
import MainSubTitle from "../threejs/MainSubTitle";
import Sun from "../threejs/Sun";
import GlobeSpinning from "../threejs/GlobeSpinning";
import Starfield from "../threejs/Starfield";
import React, { useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import NeuralNetwork from "../threejs/NeuralNetwork";

const MainScene = ({ titlePosition, subtitlePosition, demoSheet, show }) => {
  const [position, setPosition] = useState(0);
  const [showTitle, setShowTitle] = useState(true);

  return (
    <>
      {show && (
        <>
          <e.mesh theatreKey="Universe">
            <e.mesh theatreKey="Earth">
              {showTitle && (
                <>
                  <MainTitle position={titlePosition} />
                  <MainSubTitle position={subtitlePosition} />
                </>
              )}

              <Sun position={[0, -4.1, -20]} />
              <GlobeSpinning />
              {/*<Star />*/}
            </e.mesh>
            <e.mesh theatreKey="StarField">
              <Starfield
                numStars={10000}
                width={2000}
                height={2000}
                depth={2000}
                size={1}
              />
            </e.mesh>
            {/*{showContinue}*/}
          </e.mesh>
          <e.mesh theatreKey="NeuralNetwork">
            <NeuralNetwork />
          </e.mesh>
        </>
      )}
    </>
  );
};

export default MainScene;
